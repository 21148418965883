const pagesummary =
      {
        en:  [
                { title: "", des: "Please, enjoy flower photos taken every season." },
                { title: "", des: "Sarue-onshi Park" },
              ],
        jp:  [
          { title: "", des: "季節季節の花を楽しもう" },
          { title: "", des: "猿江恩賜公園" },
        ],
        kr:  [
                { title: "", des: "매 계절마다 찍은 꽃의 사진을 감상하세요." },
                { title: "", des: "사루에 온시 공원" },
              ],
        cn:  [
                { title: "", des: "赏时花照片." },
                { title: "", des: "猿江恩賜公園" },
              ]
      }

const photos =
            [{ hero: "photos/about/kabukicho.jpg" }, { hero: "photos/about/koreatown.jpg" }]

module.exports = {pagesummary, photos};
