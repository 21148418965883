<template>
  <v-container id="about" tag="section">
    <div class="mypagetitle">ABOUT</div>

    <display-page-summary :mysummary="mysummary" />

    <!--  그림 2장의 경우임 -->
    <!--
    <div style="width:100%">
      <newphoto-gallery-about :tabphotos="myphotos"></newphoto-gallery-about>
    </div>
    -->
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "HomeAbout",
  components: {
    NewphotoGalleryAbout: () =>
      import("@/components/photo-gallery/NewphotoGalleryAbout.vue"),
    DisplayPageSummary: () =>
      import("@/components/home/interface/DisplayPageSummary.vue")
  },
  computed: {
      ...mapGetters( ['stCurrentLang']),
      mylanguage() {
        return this.stCurrentLang
      },

    mysummary() {
          const {pagesummary} = require('./about/about.js')

          switch (this.mylanguage) {
            case 'en': return pagesummary.en
            case 'jp': return pagesummary.jp
            case 'kr': return pagesummary.kr
            case 'cn': return pagesummary.cn
          }
    },
      myphotos() {
        const { photos } = require('./about/about.js')
        return photos
      },

  }
};
</script>
